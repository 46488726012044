import Container from '@atoms/Container';
import Link from '@atoms/Link';
import links from 'src/constants/links';
import GetConsults from '../GetConsalt';
import ContactUs from '../ContactUs';
import useLang from 'src/hooks/useLang';

const DesktopHeader = () => {
  const lang = useLang();
  const isAr = lang === 'ar';
  return (
    <>
      <Container className="flex items-stretch justify-between">
        <Link className="w-16 !px-0">
          <img src="../../logo-sm.png" alt="Faisal" />
        </Link>
        <div className="flex gap-2">
          {links.map(link => (
            <Link
              key={link.url}
              href={link.url}
              className="flex items-center"
              variant="header"
            >
              {link[`name_${lang}`]}
            </Link>
          ))}

          <div className="p-4"></div>
          <div className="flex items-center">
            <Link className="flex items-center" href={isAr ? '/en' : '/'}>
              {isAr ? 'EN' : 'ع'}
            </Link>
          </div>
        </div>
      </Container>
    </>
  );
};

export default DesktopHeader;
